<app-waiting [message]="'Loading land'" [isWaiting]="isLoading" [fullScreen]="false" *ngIf="isLoading">
</app-waiting>

<ng-container *ngIf="!isLoading">

  <div>
    <h1>Land Plots</h1>
  </div>

  <div *ngIf="totalPlots > 0">
    <div class="total-plots">

      <div>
        <span>Total plots: </span><span>{{totalPlots}}</span>
      </div>
      <hr>
      <!-- <div>
        <span>Total redeemed : </span><span>{{xSpectarAccount!.totalPlotsClaimed}}</span>
      </div>

      <div>
        <span>Reserved plots: </span><span>{{xSpectarAccount?.totalPlotsUsed}}</span>
      </div>

      <hr> -->

      <table>

        <col width="50%">
        <col width="50%">

        <thead>
          <th></th>
          <th></th>
        </thead>

        <tbody>
          <tr>
            <td>
              <span>Total vouchers: </span><span>{{totalVoucherPlots}}</span>
            </td>
            <td>
              <span>Total plots purchased: </span><span>{{xSpectarAccount?.totalPlotsPurchased}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Total vouchers redeemed : </span><span>{{xSpectarAccount!.totalPlotsClaimed}}</span>
            </td>
            <td>
              <span>Total plots sold: </span><span>{{xSpectarAccount?.totalPlotsSold}}</span>
            </td>
          </tr>
          <tr>
            <td>
              <span>Reserved plots: </span><span>{{xSpectarAccount?.totalPlotsUsed}}</span>
            </td>
          </tr>
        </tbody>
      </table>

      <hr>
    </div>

    <div class="land">

      <div *ngIf="landNFTSales.length > 0" class="land__voucher land__voucher--fl-mint">

        <table>

          <thead>
            <th style="width: 30%;">Name</th>
            <th style="width: 10%;">Plots</th>
            <th style="width: 30%;">Date</th>
            <th style="width: 20%;">Type</th>
          </thead>
          <tbody>

            <tr *ngIf="landNFTSales.length > 0">
              <td colspan="4">
                <h3>Sales</h3>
              </td>
            </tr>
            
            <tr *ngFor="let sale of landNFTSales; index as i" class="allocations">
              <td>{{sale.nftName}}</td>
              <td>{{sale.numberOfPlots}}</td>
              <td>{{toLocalDate(sale.saleDate)}}</td>
              <td>{{getnftSaleType(sale.saleType)}}</td>
            </tr>
          </tbody>
        </table>

      </div>
    </div>

    <div class="vouchers">

      <div *ngIf="finalLandPlotAssets.length > 0" class="land__voucher land__voucher--fl-mint">

        <table>

          <thead>
            <th style="width: 30%;">Type</th>
            <th style="width: 10%;">Plots</th>
            <th style="width: 30%;">Date Received</th>
            <th style="width: 20%;">Status</th>
          </thead>
          <tbody>

            <tr *ngIf="landSaleAssets.length > 0">
              <td colspan="4">
                <h3>Vouchers</h3>
              </td>
            </tr>
            <tr *ngFor="let voucher of landSaleAssets; index as i" class="allocations">
              <td>{{voucher.assetName}}</td>
              <td>{{getVoucherPlotTotal(voucher)}}</td>
              <td>{{toLocalDate(voucher.created)}}</td>
              <td>{{voucherStatus(voucher)}}</td>
            </tr>

            <tr *ngIf="finalLandPlotAssets.length > 0">
              <td colspan="4">
                <h3>Single Plots</h3>
              </td>
            </tr>
            <tr *ngFor="let voucher of finalLandPlotAssets; index as i" class="allocations">
              <td>xSPECTAR Land Plots</td>
              <td>1</td>
              <td>{{toLocalDate(voucher.created)}}</td>
              <td>{{voucherStatus(voucher)}}</td>
            </tr>

            <tr *ngIf="freePreMintLandSaleAssets.length > 0">
              <td colspan="4">
                <h3>Free Plots</h3>
              </td>
            </tr>
            <tr *ngFor="let voucher of freePreMintLandSaleAssets; index as i" class="allocations">
              <td>{{voucher.assetName}}</td>
              <td>{{getFreePlotTotal(voucher)}}</td>
              <td>{{toLocalDate(voucher.created)}}</td>
              <td>{{voucherStatus(voucher)}}</td>
            </tr>

          </tbody>
        </table>


      </div>
    </div>
  </div>

  <div *ngIf="totalPlots == 0">
    <p class="empty-state-para">We're sorry! You dont have any land yet in the xSPECTARVERSE. Click the link below to
      get your land now!</p>

    <a class="button button--secondary" routerLink="/buy-land">Buy land</a>
  </div>
</ng-container>