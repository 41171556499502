import { Component, OnInit } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { DisplayType } from 'src/app/v2/shared/enums/display-type';
import { environment } from 'src/environments/environment';
import { AccountService } from '../../../shared/services/account.service';
import { IAgentAssetMetadata, IAsset } from 'src/app/v2/shared/models';
import { ServiceResultStatus } from 'src/app/v2/shared/services';

@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  private _currentDisplayType: DisplayType = DisplayType.Grid;

  allAgentAssets: IAsset[] = [];
  allVaultAssets: IAsset[] = [];
  allOtherAssets: IAsset[] = [];

  agent88 : IAsset | undefined;

  showXsPlot: boolean = false;
  totalXsPlots: number = 0;
  combinedAssets: IAsset[] = [];

  displayType = DisplayType;
  isLoading = true;
  displayAssets: IAsset[] = [];

  startDisplayNumber = 0;
  endDisplayNumber = 9;
  showMobileNfts: boolean = false;

  set currentDisplayType(displayType : DisplayType) {
    this._currentDisplayType = displayType;
  }

  get currentDisplayType() : DisplayType {
    return this._currentDisplayType;
  }

  constructor(private readonly accountService: AccountService) { }

  ngOnInit(): void {
    // TODO: Look at getting a new backend method to get assets by id(s)
    this.accountService
      .getAccountAssets()
      .pipe(
        finalize(() => this.isLoading = false)
      )
      .subscribe(result => {
      if (result.status === ServiceResultStatus.Success && result.data) {

        this.allAgentAssets = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.agentAssetSeriesGuid && x.assetStatusGuid == environment.activeAssetStatusGuid);
        //this.allAgentAssets = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.agentAssetSeriesGuid);

        this.allOtherAssets = result.data.filter(x =>
          x.assetStatusGuid == environment.activeAssetStatusGuid &&
          x.assetSeries.assetSeriesGuid != environment.agentAssetSeriesGuid &&
          x.assetSeries.assetCollection?.assetCollectionGuid != environment.vaultAssetCollectionGuid &&
          x.assetSeries.assetSeriesGuid != environment.the88agentAssetSeriesGuid &&
          x.assetSeries.assetSeriesGuid != environment.freeKangaAssetSeriesGuid &&
          x.assetSeries.assetSeriesGuid != environment.freeLandSeriesFromNftPreMintGuid &&
          x.assetSeries.assetSeriesGuid != environment.landParcelSeriesGuid &&
          x.assetSeries.assetCollection?.assetCollectionGuid != environment.landSalePlotsAssetCollectionGuid)

        let agents = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.the88agentAssetSeriesGuid && x.assetStatusGuid == environment.activeAssetStatusGuid);
        //let agents = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.the88agentAssetSeriesGuid);
        if (agents.length > 0) {
          this.agent88 = agents[0];
        }
        
        let landParcels = result.data.filter(x => x.assetSeries.assetSeriesGuid === environment.landParcelSeriesGuid && x.assetTypeGuid == environment.mintedUnclaimedAssetTypeGuid);

        this.combinedAssets = [...this.allAgentAssets, ...this.allOtherAssets, ...agents, ...landParcels];

        this.displayAssets = this.combinedAssets.slice(0, this.endDisplayNumber);
      }
    });
  }

  getAssetAttributeValue(asset: IAsset, value: string) {
    return asset.assetAttributes?.filter(e => e.attributeName == value)[0]?.attributeValue;
  }

  showMore() {
    this.endDisplayNumber += 9;
    this.displayAssets = this.combinedAssets.slice(0, this.endDisplayNumber);
  }

  search(event: any) {
    this.displayAssets = this.combinedAssets.filter(a => {
      const assetMetadata: IAgentAssetMetadata = a.assetMetadata as IAgentAssetMetadata;

      return assetMetadata.agentName.includes(event.target.value);
    }).slice(0, this.endDisplayNumber);
  }

  toggleNFTs() {
    this.showMobileNfts = !this.showMobileNfts;
  }
}
